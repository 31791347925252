




































import Vue from 'vue';
import { mapState } from 'vuex';
import { namespace } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';

import { AuthGroupManager } from '@kasasa/fbase-components/lib';
import { KPortalHeader, KAppToolbar, KAppToolbarBtn, KNoticeContainer, KAppToolbarMenu } from '@kasasa/fbase-components';
import { RouteName } from '@/router';

import { Site } from '@/services/SiteService';
import { RawLocation } from 'vue-router';
import { GroupPerms } from './store';
const auth = namespace('auth');
const sites = namespace('sites');

@Component({
	name: 'App',
	components: {
		KPortalHeader, KAppToolbar, KNoticeContainer, KAppToolbarBtn, KAppToolbarMenu
	},
	computed: {
		...mapState(['auth'])
	}
})
export default class App extends Vue {
	@auth.State('authManager') authManager!: AuthGroupManager;

	@sites.Action setSiteIdAction!: (siteId: string) => Promise<string>;
	@sites.Action setClientId!: (clientId: string) => Promise<string>;
	@sites.State sites !: Site[];
	@sites.State chosenSiteId !: string;


	@Watch('$route', { immediate: true, deep: true })
	loadData(): void {
		if (this.$route.params.siteId) {
			this.setSiteIdAction(this.$route.params.siteId);
		}		
		if (this.$route.params.clientId) {
			this.setClientId(this.$route.params.clientId);
		}
	}

	chooseSite(siteId: string): void {
		this.setSiteIdAction(siteId).then(() => {			
			this.$router.push(
				{
					name: RouteName.IMAGE_STYLE_LIST,
					params: {
						clientId: this.$route.params.clientId,
						siteId: this.chosenSiteId
					}
				}
			);
		});
	}

	get showSiteSelect(): boolean {
		return this.$route.meta?.showSiteSelect && !!this.$route.params.clientId;
	}

	get showAddButton(): boolean {
		return this.$route.meta?.showAdd && !!this.$route.params.siteId && this.authManager.canWrite(GroupPerms.MAIN_PERM);
	}

	navigateToAdd(): void {
		// will send to new FormPage with Add
		const route = {params: this.$route.params, name: RouteName.IMAGE_STYLE_ADD} as RawLocation;
		this.$router.push(route);
	}

}
