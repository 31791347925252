import Vue from 'vue';
import Vuex from 'vuex';
import SiteStore from '@/store/modules/SiteStore';

import { AuthStore, NoticeStore, pendoPlugin } from '@kasasa/fbase-components/lib';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		auth: AuthStore,
		notices: NoticeStore,
		sites: SiteStore,
	},
	plugins: [pendoPlugin]
});

export const enum GroupPerms {
	MAIN_PERM = 'styles'
}
