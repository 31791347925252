
















































































import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { KCardHeader, KCardHeaderBtn, KSpinner, KCardFooter, KCardFooterBtn } from '@kasasa/fbase-components';
import ImageStyleService from '@/services/ImageStyleService';
import { ImageStyle, ImageStyleFactory as imageFactory } from '@/services/api';
import { AuthGroupManager, Dialog, Alert, NoticeClass, NoticeResponse, KCrumb } from '@kasasa/fbase-components/lib';
import { namespace } from 'vuex-class';
import HIRT from '@/components/hirt';
import { Route, RawLocation, NavigationGuardNext } from 'vue-router';
import { RouteName } from '@/router';
import { GroupPerms } from '@/store';
import ImageStyleCard from '@/components/form/ImageStyleCard.vue';

const auth = namespace('auth');

@Component({
	components: {
		KSpinner,
		KCardHeader,
		KCardHeaderBtn,
		KCardFooter,
		KCardFooterBtn,
		ImageStyleCard

	}
})
export default class ImageStyleEditPage extends Vue {
	@auth.State('authManager') authManager!: AuthGroupManager;

	@Prop() siteId !: number;
	@Prop() clientId !: number;
	@Prop() imageStyleId !: number;
	@Prop({ default: 'edit'} ) readonly mode !: string;

	isLoaded = false;

	styleSvc = new ImageStyleService(this.$store);

	imageStyle = {} as ImageStyle;

	loadData = '';

	get ro() : boolean{
		return !this.authManager.canWrite(GroupPerms.MAIN_PERM);
	}

	get showActions(): boolean {
		return this.$route.meta?.mode === 'edit';
	}

	get title(): string {
		return this.$route.meta?.mode == 'add' ? 'Add New Image Style' : 'Edit';
	}

	get crumbs(): KCrumb[] {
		const crumbs = [] as KCrumb[];
		crumbs.push(
			{
				key: '0',
				text: 'Image Styles',
				disabled: false,
				link: true,
				exact: true,
				to: { name: RouteName.IMAGE_STYLE_LIST, params: { clientId: this.clientId.toString(), siteId: this.siteId.toString() } }
			}
		);

		if (this.$route.meta?.mode == 'add') {
			crumbs.push({
				key:'1',
				text: 'Add New Image Style',
				disabled: true
			});
		} else {
			crumbs.push({
				key: '2',
				text: this.title,
				disabled: true
			});
		}
		return crumbs;
	}

	loadImageStyle(): void {
		this.styleSvc.find(this.clientId, this.siteId, this.imageStyleId)
			.then(resp => {
				this.imageStyle = resp.data.data;
				this.isLoaded = true;
				this.setLoadedData(this.imageStyle);
			});
	}

	@Watch('$route', {immediate: true, deep: true})
	load(): void {

		// load the ImageStyle object
		if (this.mode === 'edit') {
			this.loadImageStyle();
		
		} else {
			this.imageStyle = imageFactory(this.siteId);
			this.isLoaded = true;

			// store initial state
			this.setLoadedData(this.imageStyle);
		}

	}

	setLoadedData(style: ImageStyle): void {
		this.loadData = JSON.stringify(style);
	}


	async deleteStyle(): Promise<void> {
		const dialog = new Dialog(
			'Delete this Image Style?',
			`Are you sure you want to delete "${this.imageStyle?.name}"? Caution: this can't be undone.`,
			'DELETE'
		);

		dialog.setDeclineLabel('CANCEL')
			.setDismissable(false);
		const success = new Alert(`${this.imageStyle?.name} has been deleted.`, NoticeClass.SUCCESS);

		success.setTimeout(6000);

		const res = await this.$store.dispatch('notices/add', dialog);
		switch (res) {
			case NoticeResponse.ACCEPT:
				this.styleSvc.delete(this.clientId, this.siteId, this.imageStyleId)
					.then(() => {
						this.$store.dispatch('notices/add', success);
						this.reset();
						this.goToList();
					})
					.catch((err) => {

						// might be 409, in which case we have a prepared snackbar
						switch (err.response.status) {
							case 409:
							default:
								this.$store.dispatch('notices/add', new Alert(
									`${this.imageStyle.name} could not be deleted because it is currently in use.`,
									NoticeClass.ERROR
								)
								);
								break;
						}
					});
				break;
			case NoticeResponse.DECLINE:
			default:
				// do nothing
				// the modal closes
				break;
		}
	}

	cancelAction(): void {
		// beforeRouteLeave() will detect unsaved changes
		this.goToList();
	}

	generateSaveActionPromise(): Promise<void> {
		const saved = new Alert(`${this.imageStyle.name} is successfully saved.`, NoticeClass.SUCCESS);
		saved.setTimeout(6000);

		return new Promise((resolve, reject) => {
			if (this.$route.meta?.mode === 'add') {
				this.styleSvc.create(this.clientId, this.siteId, this.imageStyle)
					.then((resp) => {
						this.$store.dispatch('notices/add', saved);

						// just in case
						this.imageStyle = resp.data.data;

						// reset all the vuelidates and loadedData;
						this.reset();

						// will send to new FormPage, however saveAndClose will override and send to navlist
						const params = {...this.$route.params, imageStyleId: this.imageStyle.id || ''};
						const route = {params: params, name: RouteName.IMAGE_STYLE_EDIT} as RawLocation;
						this.$router.push(route);

						resolve();
					})
					.catch(() => {
						const error = new Alert(`${this.imageStyle.name} failed to save.`, NoticeClass.ERROR);
						error.setTimeout(6000);
						this.$store.dispatch('notices/add', error);
						reject();
					});
			} else {
				this.styleSvc.update(this.clientId, this.siteId, this.imageStyleId, this.imageStyle)
					.then((resp) => {
						this.imageStyle = resp.data.data;
						this.$store.dispatch('notices/add', saved);

						// reset all the vuelidates;
						this.reset();
						
						this.loadImageStyle();

						resolve();
					})
					.catch((err) => {
						// might be 409, in which case we have a prepared snackbar
						switch (err.response.status) {
							case 409:
								this.$store.dispatch('notices/add', new Alert(
									`Unable to change Type because ${this.imageStyle.name} is currently in use.`,
									NoticeClass.ERROR
								));
								break;
							default:
								this.$store.dispatch('notices/add', new Alert(
									`${this.imageStyle.name} failed to save.`, 
									NoticeClass.ERROR
								));
								break;
						}
						reject();
					});
			}
		});
	}

	saveAction(): Promise<void> | void {
		if (this.checkForErrors()) {
			return;
		}

		return this.generateSaveActionPromise();
	}

	saveCloseAction(): Promise<void> | void {
		if (this.checkForErrors()) {
			return;
		}

		return this.generateSaveActionPromise().then(() => {
			this.goToList();
		});
	}

	goToList(): void {
		const route = {params: this.$route.params, name: RouteName.IMAGE_STYLE_LIST} as RawLocation;
		this.$router.push(route);
	}

	checkForErrors(): boolean {
		let errors = false;

		const header = this.$refs.styleCard as ImageStyleCard & HIRT;

		header.touch();

		errors = header.hasErrors();

		if (errors) {
			const broken = new Alert('Unable to save. Please make sure all required fields are completed without errors.', NoticeClass.ERROR);
			this.$store.dispatch('notices/add', broken);
		}

		return errors;
	}

	checkForDirty(): boolean {
		return (this.$refs.styleCard as ImageStyleCard & HIRT).isDirty() || this.isDirty;
	}

	hasErrors(): boolean {
		return (this.$refs.styleCard as ImageStyleCard & HIRT).hasErrors();
	}

	reset(): void {
		this.setLoadedData(this.imageStyle);
		(this.$refs.styleCard as ImageStyleCard & HIRT).reset();
	}

	touch(): void {
		(this.$refs.styleCard as ImageStyleCard & HIRT).touch();
	}

	async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
		if (this.checkForDirty()) {
			const dialog = new Dialog('Unsaved Changes', 'You have unsaved changes on this page that will be lost if you leave now. Are you sure?', 'LEAVE WITHOUT SAVING');
			dialog.setDeclineLabel('STAY ON THIS PAGE')
				.setDismissable(false);

			const res = await this.$store.dispatch('notices/add', dialog);
			switch (res) {
				case NoticeResponse.ACCEPT:
					this.reset();
					next();
					break;
				case NoticeResponse.DECLINE:
				default:
					// staying on the page
					break;
			}
		} else {
			this.reset();
			next();
		}
	}

	get isDirty(): boolean {
		return this.loadData !== JSON.stringify(this.imageStyle);
	}

	copyStyle(): void {
		this.styleSvc.copy(this.clientId, this.siteId, JSON.parse(this.loadData))
			.then((resp) => {
				const saved = new Alert(`${this.imageStyle.name} was successfully copied.`, NoticeClass.SUCCESS);
				saved.setTimeout(6000);
				this.$store.dispatch('notices/add', saved);

				const imageStyle = resp.data.data;

				// will send to new EditPage
				const params = {...this.$route.params, imageStyleId: imageStyle.id || ''};
				const route = {params: params, name: RouteName.IMAGE_STYLE_EDIT} as RawLocation;
				this.$router.push(route);
			})
			.catch(() => {
				const error = new Alert(`${this.imageStyle.name} failed to copy.`, NoticeClass.ERROR);
				error.setTimeout(6000);
				this.$store.dispatch('notices/add', error);
			});
	}
}

