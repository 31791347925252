export interface ImageStyle {
	id: number | null;
	fkSite: number;
	name: string;
	width: number;
	height: number;
	fkImageStyleCategory: number | null;
	categoryKey: ImageStyleCategory;
}

export function ImageStyleFactory(fkSite: number): ImageStyle {
	return {
		id: null,
		fkSite: fkSite,
		name: '',
		width: 100,
		height: 100,
		fkImageStyleCategory: null,
		categoryKey: ImageStyleCategory.page1,
	} as ImageStyle;
}

export enum ImageStyleCategory {
	page1 = 'page1',
	page2 = 'page2',
	page3 = 'page3'
}
