
















import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { KSpinner } from '@kasasa/fbase-components';

import { AuthGroupManager } from '@kasasa/fbase-components/lib';
import ImageStyleListTable from '@/components/ImageStyleListTable.vue';
import ImageStyleService from '@/services/ImageStyleService';
import { ImageStyle } from '@/services/api';

import { namespace } from 'vuex-class';
import { GroupPerms } from '@/store';
const auth = namespace('auth');

@Component({
	components: {
		KSpinner,
		ImageStyleListTable
	}
})
export default class ListImageStylesPage extends Vue {
	@auth.State('authManager') authManager!: AuthGroupManager;

	@Prop() clientId !: number;
	@Prop() siteId !: number;

	status: 'loading' | 'loaded' | 'empty' = 'loading';

	imageStyles: ImageStyle[] = [];
	imageStyleSvc = new ImageStyleService(this.$store);

	get ro() : boolean{
		return !this.authManager.canWrite(GroupPerms.MAIN_PERM);
	}

	@Watch('$route', {immediate: true, deep: true})
	loadImageStyles(): void {
		this.status = 'loading';
		this.imageStyles = [];

		this.imageStyleSvc.findAll(this.clientId, this.siteId)
			.then((resp) => {
				this.imageStyles = resp.data.data;
				this.status = (this.imageStyles.length ? 'loaded' : 'empty');
			});
	}
}
