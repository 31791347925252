

















































import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { KCardHeader, KCardHeaderBtn, KSpinner, KCardFooter, KCardFooterBtn } from '@kasasa/fbase-components';
import ImageStyleService from '@/services/ImageStyleService';
import { ImageStyle } from '@/services/api';
import { AuthGroupManager, KCrumb } from '@kasasa/fbase-components/lib';
import { namespace } from 'vuex-class';
import { RawLocation } from 'vue-router';
import { RouteName } from '@/router';
import ImageStyleCard from '@/components/form/ImageStyleCard.vue';

const auth = namespace('auth');

@Component({
	components: {
		KSpinner,
		KCardHeader,
		KCardHeaderBtn,
		KCardFooter,
		KCardFooterBtn,
		ImageStyleCard
	}
})
export default class ImageStylePreview extends Vue {
	@auth.State('authManager') authManager!: AuthGroupManager;

	@Prop() siteId !: number;
	@Prop() clientId !: number;
	@Prop() imageStyleId !: number;

	isLoaded = false;

	title = 'View';

	styleSvc = new ImageStyleService(this.$store);

	imageStyle = {} as ImageStyle;

	get crumbs(): KCrumb[] {
		const crumbs = [];
		crumbs.push(
			{
				key: '0',
				text: 'Image Styles',
				disabled: false,
				link: true,
				exact: true,
				to: { name: RouteName.IMAGE_STYLE_LIST, params: { clientId: this.clientId.toString(), siteId: this.siteId.toString() } }
			},
			{
				key: '1',
				text: this.title,
				disabled: true
			}
		);
		return crumbs;
	}

	@Watch('$route', {immediate: true, deep: true})
	load(): void {
		this.styleSvc.find(this.clientId, this.siteId, this.imageStyleId)
			.then(resp => {
				this.imageStyle = resp.data.data;
				this.isLoaded = true;
			});
	}

	cancelAction(): void {
		const route = {params: this.$route.params, name: RouteName.IMAGE_STYLE_LIST} as RawLocation;
		this.$router.push(route);
	}

}

