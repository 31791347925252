import { ImageStyleCategory } from "@/services/api";

export const categories = [
	{
		text: 'Image Style 1',
		value: ImageStyleCategory.page1
	},
	{
		text: 'Image Style 2',
		value: ImageStyleCategory.page2
	},
	{
		text: 'Image Style 3',
		value: ImageStyleCategory.page3
	}
];