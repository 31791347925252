






























































import { Component, Prop, VModel } from 'vue-property-decorator';
import Vue from 'vue';
import HIRT from '@/components/hirt';
import { required, numeric } from 'vuelidate/lib/validators';
import { RuleDecl } from 'vue/types/options';
import { ImageStyle } from '@/services/api';
import { KText, KSelect } from '@kasasa/fbase-components';
import { categories } from '@/utils/categories';


@Component({
	components: {
		KText, KSelect
	},
})
export default class ImageStyleCard extends Vue implements HIRT {
	@VModel() localStyle!: ImageStyle;
	@Prop({ default: false }) readonly ro!: boolean;

	pageOptions = categories;

	isMounted = false;
	mounted(): void {
		this.isMounted = true;
	}

	validations(): RuleDecl {
		return {
			localStyle: {
				name: { required },
				height: { required, numeric },
				width: { required, numeric },
				categoryKey: { required }
			}
		};
	}

	hasErrors(): boolean {
		return this.$v?.$anyError;
	}

	isDirty(): boolean {
		return this.$v?.$anyDirty;
	}

	reset(): void {
		this.$v?.$reset();
	}

	touch(): void{
		this.$v?.$touch();
	}

	get widthErrorMessage(): string | null{
		return this.isMounted ? (
			(this.$v?.localStyle?.width?.$invalid && this.$v?.localStyle?.width?.$dirty) ? 'Width is required and only numbers are allowed' : null
		) : null;
	}

	get heightErrorMessage(): string | null{
		return this.isMounted ? (
			(this.$v?.localStyle?.height?.$invalid && this.$v?.localStyle?.height?.$dirty) ? 'Height is required and only numbers are allowed' : null
		) : null;
	}

	get nameErrorMessage(): string | null {
		return this.isMounted ? (
			(this.$v?.localStyle?.name?.$invalid && this.$v?.localStyle?.name?.$dirty) ? 'Name is required' : null
		) : null;
	}

	get categoryErrorMessage(): string | null {
		return this.isMounted ? (
			(this.$v?.localStyle?.categoryKey?.$invalid && this.$v?.localStyle?.categoryKey?.$dirty) ? 'Type is required' : null
		) : null;
	}

	
}
