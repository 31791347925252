import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { KSelectFi, KSelectSite } from '@kasasa/fbase-components';
import RouterView from '@/components/RouterView.vue';
import ImageStyleEditPage from '@/pages/ImageStyleFormPage.vue';
import ImageStylePreview from '@/pages/ImageStylePreview.vue';
import ListImageStylesPage from '@/pages/ListImageStylesPage.vue';

Vue.use(VueRouter);

const mergeMeta = (route: Route): Dictionary<string> => {
	return { ...route.params, ...route.meta };
};

export const RouteName = {
	HOME: 'Home',
	CLIENT_HOME: 'ClientHome',
	IMAGE_STYLE_LIST: 'ImageStyleList',
	IMAGE_STYLE_ADD: 'ImageStyleAdd',
	IMAGE_STYLE_EDIT: 'ImageStyleEdit',
	IMAGE_STYLE_VIEW: 'ImageStyleView'
};

const routes = [
	{
		path: '/',
		name: RouteName.HOME,
		component: KSelectFi
	},
	{
		path: '/:clientId([1-9]\\d*)',
		component: RouterView,
		children: [
			{
				path: '',
				name: RouteName.CLIENT_HOME,
				component: KSelectSite,
				props: mergeMeta,
				meta: {
					showSiteSelect: true
				},
			},
			{
				path: 'site/:siteId([1-9]\\d*)',
				redirect: { name: RouteName.IMAGE_STYLE_LIST },
				component: RouterView,
				children: [
					{
						path: 'image-style',
						name: RouteName.IMAGE_STYLE_LIST,
						component: ListImageStylesPage,
						props: mergeMeta,
						meta: {
							showSiteSelect: true,
							showAdd: true,
						},
					},
					{
						path: 'add',
						name: RouteName.IMAGE_STYLE_ADD,
						component: ImageStyleEditPage,
						props: mergeMeta,
						meta: {
							showSiteSelect: true,
							mode: 'add'
						},
					},
					{
						path: 'image-style/:imageStyleId([1-9]\\d*)',
						name: RouteName.IMAGE_STYLE_VIEW,
						component: ImageStylePreview,
						props: mergeMeta,
						meta: {
							showSiteSelect: true,
							mode: 'edit'
						},
					},
					{
						path: 'image-style/:imageStyleId([1-9]\\d*)/edit',
						name: RouteName.IMAGE_STYLE_EDIT,
						component: ImageStyleEditPage,
						props: mergeMeta,
						meta: {
							showSiteSelect: true,
							mode: 'edit'
						},
					}
				],
			},
		],
	},
	{
		path: '*',
		redirect: { name: RouteName.HOME }
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;
