import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelope, ResponseEnvelopeCollection } from '@kasasa/fbase-components/lib';
import { ImageStyle } from '@/services/api';

export default class ImageStyleService extends FBaseService {

	findAll(clientId: number, siteId: number): Promise<AxiosResponse<ResponseEnvelopeCollection<ImageStyle>>> {
		return this.apiClient.get(`/api/kcms-imagestyle/${clientId}/site/${siteId}/image-style`);
	}

	find(clientId: number, siteId: number, id: number): Promise<AxiosResponse<ResponseEnvelope<ImageStyle>>> {
		return this.apiClient.get(`/api/kcms-imagestyle/${clientId}/site/${siteId}/image-style/${id}`);
	}

	create(clientId: number, siteId: number, imageStyle: ImageStyle): Promise<AxiosResponse<ResponseEnvelope<ImageStyle>>> {
		return this.apiClient.post(`/api/kcms-imagestyle/${clientId}/site/${siteId}/image-style`, imageStyle);
	}

	update(clientId: number, siteId: number, imageStyleId: number, imageStyle: ImageStyle): Promise<AxiosResponse<ResponseEnvelope<ImageStyle>>> {
		return this.apiClient.put(`/api/kcms-imagestyle/${clientId}/site/${siteId}/image-style/${imageStyleId}`, imageStyle);
	}

	delete(clientId: number, siteId: number, imageStyleId: number): Promise<AxiosResponse<ResponseEnvelope<ImageStyle>>> {
		return this.apiClient.delete(`/api/kcms-imagestyle/${clientId}/site/${siteId}/image-style/${imageStyleId}`);
	}

	copy(clientId: number, siteId: number, imageStyle: ImageStyle): Promise<AxiosResponse<ResponseEnvelope<ImageStyle>>> {
		imageStyle.name = imageStyle.name + ' copy';
		imageStyle.id = null;
		return this.apiClient.post(`/api/kcms-imagestyle/${clientId}/site/${siteId}/image-style`, imageStyle);
	}
}
