













































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ImageStyle } from '@/services/api';
import { DataTableHeader } from 'vuetify';
import { KScrollBtn } from '@kasasa/fbase-components';
import { RouteName } from '@/router';
import { categories } from '@/utils/categories';

@Component({
	components: {
		KScrollBtn
	},
})
export default class ImageStyleListTable extends Vue {
	@Prop() items!: ImageStyle[];
	@Prop() readonly clientId !: number;
	@Prop() readonly siteId !: number;
	@Prop({ default: false }) readonly ro !: boolean;

	get headers(): DataTableHeader[] {
		const dataheaders: DataTableHeader[] = [
			{ text: 'Name', value: 'name'},
			{ text: 'Type', value: 'categoryKey' },
			{ text: 'Width', value: 'width'},
			{ text: 'Height', value: 'height'},
		];

		return dataheaders;
	}


	handleClick(row: ImageStyle): void {
		if (!row.id) {
			return;
		}

		const routeName = this.ro ? RouteName.IMAGE_STYLE_VIEW : RouteName.IMAGE_STYLE_EDIT;
		this.$router.push({name: routeName, params: { clientId: this.clientId.toString(), siteId: this.siteId.toString(), imageStyleId: row.id.toString() }});
	}

	getTypeFromCategoryKey(categoryKey: string): string {
		const category = categories.find(category => category.value == categoryKey);

		if (!category) {
			return '[Not Set]';
		}

		return category.text;
	}

}
